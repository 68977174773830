import { useExpandedFdocContext } from '@/src/components/ExpandedFdoc/ExpandedFdocProvider';
import { ExpandedFdocSidebar_SimilarContent } from '@/src/components/ExpandedFdoc/ExpandedFdocSidebar_SimilarContent';
import { useDomContentRect } from '@/src/hooks/useDomRect';
import SimilarIconFilled from '@/src/icons/SimilarIconFilled';
import { useQueryComments } from '@/src/modules/comments/queries/useQueryComments';
import { ExtraPanelTabAssistant } from '@/src/modules/resource-detail/components/ExpandedResource/ExtraPanel/Assistant/ExtraPanelTabAssistant';
import { ExtraPanelTabComments } from '@/src/modules/resource-detail/components/ExpandedResource/ExtraPanel/Comments/ExtraPanelTabComments';
import InfoIcon from '@/src/modules/resource-detail/components/ExpandedResource/ExtraPanel/Info/assets/info-tab-item-icon.svg';
import { ExtraPanelTab } from '@/src/modules/resource-detail/components/ExpandedResource/ExtraPanel/types';
import { Flex } from '@/src/modules/ui/components/Flex';
import React from 'react';
import { ExtraPanelTabButton } from './components/TabButton';

import { useResponsive } from '@/src/hooks/responsive';
import { ExtraPanelInfo } from '@/src/modules/resource-detail/components/ExpandedResource/ExtraPanel/Info/ExtraPanelInfo';
import AssistantIcon from './Assistant/assets/assistant-tab-item-icon.svg';

interface ExtraPanelContentPropsNoResource {
  isContentEnabled: false;
  resourceId: string;
  onTabItemSelect?: VoidFunction;
}
interface ExtraPanelContentProps {
  isContentEnabled: boolean;
  resourceId: string;
  onTabItemSelect?: VoidFunction;
}

export const ExtraPanelContent: React.FC<
  ExtraPanelContentProps | ExtraPanelContentPropsNoResource
> = ({ isContentEnabled, resourceId, onTabItemSelect }) => {
  const { selectedExtraPanel, setSelectedExtraPanel } = useExpandedFdocContext();
  const { isXSView, isXSSView } = useResponsive();

  const { comments } = useQueryComments(resourceId, {
    enabled: isContentEnabled,
  });

  const handleClickTabButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    const nextTab = e.currentTarget.value as ExtraPanelTab;
    setSelectedExtraPanel(nextTab);
    onTabItemSelect?.();
  };

  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const [wrapperRect] = useDomContentRect(wrapperRef.current);

  return (
    <Flex direction="column" style={{ height: '100%', position: 'relative' }} ref={wrapperRef}>
      <Flex
        alignItems="center"
        justifyContent="center"
        gap={isXSView || isXSSView ? 'element' : 'elementsContainer'}
        style={{
          padding: '.75rem',
          flexShrink: 0,
          borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
        }}
        flexGrow={0}
      >
        <ExtraPanelTabButton
          variant="comments"
          active={selectedExtraPanel === 'comments'}
          isFullscreen={false}
          tabIndex={7}
          value="comments"
          /**
           * Using onClick to make sure it does acts natively,
           * so if the user presses and then pans the resizable container it wont tigger the click event.
           */
          onClick={handleClickTabButton}
        >
          Comments <ExtraPanelTabButton.Count>{comments.length}</ExtraPanelTabButton.Count>
        </ExtraPanelTabButton>
        <ExtraPanelTabButton
          variant="similar"
          active={selectedExtraPanel === 'similar'}
          isFullscreen={false}
          tabIndex={7}
          value="similar"
          /**
           * Using onClick to make sure it does acts natively,
           * so if the user presses and then pans the resizable container it wont tigger the click event.
           */
          onClick={handleClickTabButton}
        >
          <SimilarIconFilled color={'#FFF'} />
          Similar
        </ExtraPanelTabButton>
        <ExtraPanelTabButton
          variant="similar"
          active={selectedExtraPanel === 'assistant'}
          isFullscreen={false}
          tabIndex={7}
          value="assistant"
          /**
           * Using onClick to make sure it does acts natively,
           * so if the user presses and then pans the resizable container it wont tigger the click event.
           */
          onClick={handleClickTabButton}
        >
          <AssistantIcon style={{ width: 20, height: 20 }} />
          Ask
        </ExtraPanelTabButton>
        <ExtraPanelTabButton
          variant="similar"
          active={selectedExtraPanel === 'info'}
          isFullscreen={false}
          tabIndex={7}
          value="info"
          onClick={handleClickTabButton}
          square={isXSSView}
        >
          {isXSSView ? <InfoIcon style={{ width: 16, height: 16 }} /> : 'Info'}
        </ExtraPanelTabButton>
      </Flex>
      {isContentEnabled &&
        (selectedExtraPanel === 'comments' ? (
          <Flex
            direction="column"
            flexGrow={1}
            style={{ overflow: 'hidden', position: 'relative' }}
          >
            <ExtraPanelTabComments
              commentInputVisible={wrapperRect?.height ? wrapperRect.height > 190 : false}
              resourceId={resourceId}
            />
          </Flex>
        ) : selectedExtraPanel === 'similar' ? (
          <ExpandedFdocSidebar_SimilarContent
            /**
             * @TODO
             */
            hideSimilar={false}
            isFullscreen={false}
          />
        ) : selectedExtraPanel === 'assistant' ? (
          <ExtraPanelTabAssistant
            commentInputVisible={wrapperRect?.height ? wrapperRect.height > 250 : false}
          />
        ) : selectedExtraPanel === 'info' ? (
          <ExtraPanelInfo />
        ) : null)}
    </Flex>
  );
};
