import FileDisplay from '@/src/components/FileDisplay/FileDisplay';
import { ExpandedResourceDropdownMenu } from '@/src/modules/resource-detail/components/ExpandedResource/components/ExpandedResourceDropdownMenu';
import { HeaderWrapper } from '@/src/modules/resource-detail/components/ExpandedResource/components/ModalResourceHeader';
import { useResourceDefaultDataContextSafe } from '@/src/modules/resource-detail/components/context/resourceDataContext';
import { Flex } from '@/src/modules/ui/components/Flex';
import { ButtonIconDownload } from '@/src/modules/ui/components/button/ButtonIconDownload';
import React from 'react';

export const ResourceContentDefault: React.FC = () => {
  const { resource, handleDownload } = useResourceDefaultDataContextSafe();

  return (
    <>
      <HeaderWrapper>
        <HeaderWrapper.Left />
        <HeaderWrapper.Right>
          {handleDownload && <ButtonIconDownload onClick={handleDownload} tabIndex={4} />}
          <ExpandedResourceDropdownMenu />
        </HeaderWrapper.Right>
      </HeaderWrapper>
      <Flex flexGrow={1} justifyContent="center" alignItems="center">
        <FileDisplay
          fileExtension={resource.extension}
          fileName={resource.name || 'untitled'}
          onClick={handleDownload}
        />
      </Flex>
    </>
  );
};
