import MultiplayerEditor from '@/src/components/Tiptap/MultiplayerEditor';
import { HeaderWrapper } from '@/src/modules/resource-detail/components/ExpandedResource/components/ModalResourceHeader';

import CloudTickIcon from '@/public/images/icons/CloudTick.svg';
import UpdateIcon from '@/public/images/icons/Update.svg';
import { useExpandedFdocContext } from '@/src/components/ExpandedFdoc/ExpandedFdocProvider';
import useAuthStore from '@/src/hooks/auth';
import { useDebouncedCallback } from '@/src/hooks/useDebouncedCallback';
import useUploadNotepadImage from '@/src/lib/image';
import { AnalyticsEvents } from '@/src/modules/analytics/analytics.types';
import { useAnalytics } from '@/src/modules/analytics/hooks/useAnalytics';
import { useResourceNotepadDataContextSafe } from '@/src/modules/resource-detail/components/context/resourceDataContext';
import { ExpandedResourceDropdownMenu } from '@/src/modules/resource-detail/components/ExpandedResource/components/ExpandedResourceDropdownMenu';
import { ExpandedResourceTitle } from '@/src/modules/resource-detail/components/ExpandedResource/components/ResourceTitle';
import {
  useMutationNotepadStateUpdate,
  useOptimisticNotepadStateUpdate,
} from '@/src/modules/resource-detail/mutations/useMutationNotepadStateUpdate';
import { HybridSyncProviderMode } from '@/src/modules/resource-detail/utils/HybridSyncProvider';
import { ResourceDetailNotepad } from '@/src/modules/resources/resources.types';
import Tooltip from '@/src/ui/Tooltip';
import { isTruthy } from '@/src/utils/guards';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { shallow } from 'zustand/shallow';

const EditorWrapper = styled.div`
  height: 1px;
  flex-grow: 1;
  position: relative;

  .tiptap_editor_content {
    padding: 1.5rem;
  }
`;

export const ResourceContentNotepad: React.FC = () => {
  const { resource } = useResourceNotepadDataContextSafe();

  const { track } = useAnalytics();
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn, shallow);
  const uploadNotepadImage = useUploadNotepadImage(resource.id);
  const { setToolbarHeight, isEditorFocused, setIsEditorFocused } = useExpandedFdocContext();

  /**************************************************************************************************
   * internal state
   */
  const [hasPendingChanges, setHasPendingChanges] = React.useState<boolean>(false);
  const [hybridSyncMode, setHybridSyncMode] = React.useState<HybridSyncProviderMode>(
    HybridSyncProviderMode.FullyOffline,
  );
  const [hasSynced, setHasSynced] = React.useState<boolean>(false);

  const onSync = React.useCallback(() => {
    setHasSynced(true);
  }, []);

  const dynamicContentRef = useRef<ResourceDetailNotepad['data']['preview']>(resource.data.preview);

  /**************************************************************************************************
   * tracking
   */
  const trackAnalyticsNotepadEdit = React.useCallback(() => {
    track(AnalyticsEvents.NotepadEdit);
  }, [track]);

  const trackAnalyticsNotepadEditDebounced = useDebouncedCallback(trackAnalyticsNotepadEdit, 1000);

  /**************************************************************************************************
   * update the notepad content
   */

  const optimisticNotepadStateUpdate = useOptimisticNotepadStateUpdate();
  const { mutate: mutateNotepadState, isPending: isNotepadStatePending } =
    useMutationNotepadStateUpdate();

  /**************************************************************************************************
   * sync status
   */

  const isSynced = [
    hybridSyncMode === HybridSyncProviderMode.Online,
    hybridSyncMode === HybridSyncProviderMode.FullyOffline &&
      !hasPendingChanges &&
      !isNotepadStatePending,
  ].some(isTruthy);

  const savingText = React.useMemo(() => {
    if (!hasSynced) return 'Loading...';
    if (hybridSyncMode === HybridSyncProviderMode.FullyOffline) {
      if (isNotepadStatePending) return 'Saving...';
      if (hasPendingChanges) return 'Pending Changes';
      return 'Saved';
    }
    if (hybridSyncMode === HybridSyncProviderMode.SemiOffline) return 'Connecting...';
    return `Connected and syncing`;
  }, [hasSynced, hybridSyncMode, isNotepadStatePending, hasPendingChanges]);

  return (
    <>
      <HeaderWrapper>
        <HeaderWrapper.Left>
          <ExpandedResourceTitle />
        </HeaderWrapper.Left>
        <HeaderWrapper.Right>
          <Tooltip label={savingText} delay={0}>
            <span>
              {isSynced ? (
                <CloudTickIcon
                  data-testid="fdoc-saved-icon"
                  style={{
                    width: 28,
                    height: 'auto',
                  }}
                />
              ) : (
                <UpdateIcon
                  data-testid="fdoc-needs-saving-icon"
                  className={
                    hybridSyncMode === HybridSyncProviderMode.SemiOffline || isNotepadStatePending
                      ? 'animate-spin'
                      : undefined
                  }
                  style={{
                    color: 'var(--fabric-color-text-tertiary)',
                    width: 28,
                    height: 'auto',
                  }}
                />
              )}
            </span>
          </Tooltip>
          <ExpandedResourceDropdownMenu />
        </HeaderWrapper.Right>
      </HeaderWrapper>

      <EditorWrapper data-pan-id-ignore-expanded-resource={isEditorFocused ? 'xy' : false}>
        <MultiplayerEditor
          onModeChange={setHybridSyncMode}
          onHasPendingChanges={setHasPendingChanges}
          onStateSynced={onSync}
          onOfflineChange={(update, inMultiplayer?: boolean) => {
            const changes = {
              resource: resource,
              update,
              optimisticContent: dynamicContentRef.current,
            };

            if (inMultiplayer) {
              optimisticNotepadStateUpdate(changes);
              return;
            }
            mutateNotepadState(changes);
          }}
          tiptapProps={{
            editable: isLoggedIn,
            initialValue: resource.data.preview,
            contentClassName: 'tiptap_editor_content',
            onToolbarHeightChange: setToolbarHeight,
            onUploadImage: uploadNotepadImage ?? undefined,
            tabIndex: '10',
            autoFocus: false, // @TODO true if draft
            onEditorFocusChange: setIsEditorFocused,
            onChange: (value) => {
              dynamicContentRef.current = value;
              trackAnalyticsNotepadEditDebounced();
            },
          }}
        />
      </EditorWrapper>
    </>
  );
};
