import { ResourceAudioPlayer } from '@/src/modules/resource-detail/components/ExpandedResource/Content/Audio/ResourceAudioPlayer';
import { ExpandedResourceDropdownMenu } from '@/src/modules/resource-detail/components/ExpandedResource/components/ExpandedResourceDropdownMenu';
import { HeaderWrapper } from '@/src/modules/resource-detail/components/ExpandedResource/components/ModalResourceHeader';
import { ExpandedResourceTitle } from '@/src/modules/resource-detail/components/ExpandedResource/components/ResourceTitle';
import { useResourceAudioDataContextSafe } from '@/src/modules/resource-detail/components/context/resourceDataContext';
import { Flex } from '@/src/modules/ui/components/Flex';
import { ButtonIconDownload } from '@/src/modules/ui/components/button/ButtonIconDownload';
import React from 'react';

export const ResourceContentAudio: React.FC = () => {
  const { handleDownload } = useResourceAudioDataContextSafe();

  return (
    <>
      <HeaderWrapper>
        <HeaderWrapper.Left>
          <ExpandedResourceTitle />
        </HeaderWrapper.Left>
        <HeaderWrapper.Right>
          <ButtonIconDownload onClick={handleDownload} tabIndex={4} />
          <ExpandedResourceDropdownMenu />
        </HeaderWrapper.Right>
      </HeaderWrapper>
      <Flex
        direction="column"
        overflow="hidden"
        alignItems="center"
        style={{
          padding: '1rem',
        }}
      >
        <ResourceAudioPlayer />
      </Flex>
    </>
  );
};
