import ExpandedFdocCommentsTab from '@/src/components/ExpandedFdoc/ExpandedFdocCommentsTab';
import { ExpandedFdocSidebarAddComment } from '@/src/components/ExpandedFdoc/ExpandedFdocSidebar_AddComment';
import { CommentWithAuthor } from '@/src/modules/comments/comments.types';
import { useQueryComments } from '@/src/modules/comments/queries/useQueryComments';
import { OptimisticDraft } from '@/src/types/draftable';
import { AnimatePresence } from 'framer-motion';
import React from 'react';

export const ExtraPanelTabComments: React.FC<{
  resourceId: string;
  commentInputVisible: boolean;
}> = ({ commentInputVisible, resourceId }) => {
  const [editingComment, setEditingComment] =
    React.useState<OptimisticDraft<CommentWithAuthor> | null>(null);

  const { comments } = useQueryComments(resourceId);

  const isInputVisible = commentInputVisible || editingComment;

  return (
    <>
      <ExpandedFdocCommentsTab
        comments={comments}
        resourceId={resourceId}
        isFullscreen={false}
        onStartEditingComment={setEditingComment}
      />
      <AnimatePresence>
        {isInputVisible && (
          <ExpandedFdocSidebarAddComment
            editingComment={editingComment}
            onCancelEditingComment={() => {
              setEditingComment(null);
            }}
            isFullscreen={false}
            resourceId={resourceId}
            canAutoFocus={false}
          />
        )}
      </AnimatePresence>
    </>
  );
};
