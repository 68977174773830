import { ResourceTitleUrlV2 } from '@/src/modules/resource-detail/components/ResourceTitle/ResourceTitleUrl';
import styled from 'styled-components';

/**
 * using default component of the older implementation
 */
export const ResourceTitleUrlOverride = styled(ResourceTitleUrlV2)`
  // remove below when not extending
  // this is the original link out icon, we're displaying it elsewhere now
  svg {
    display: none;
  }
`;
