import { useExpandedFdocContext } from '@/src/components/ExpandedFdoc/ExpandedFdocProvider';
import { useAuthUser } from '@/src/hooks/auth';
import { useInputControls } from '@/src/hooks/useInputControls';
import { useResourceDataContext } from '@/src/modules/resource-detail/components/context/resourceDataContext';
import { ResourceTitleEditable } from '@/src/modules/resource-detail/components/ResourceTitle/ResourceTitleEditable';
import { useMutationResourceTitleRename } from '@/src/modules/resource-detail/mutations/useMutationResourceTitleRename';
import styled from 'styled-components';

const NewModalWrapper = styled.div`
  overflow-x: hidden;
  height: 100%;
  display: flex;
  align-items: center;
  & > * {
    margin-left: 0;
  }
`;

export const ExpandedResourceTitle: React.FC = () => {
  const { resource } = useResourceDataContext();
  const resourceTitle = resource?.name || '';

  const { isAnon } = useExpandedFdocContext();

  const { mutate: mutateResourceTitleRename } = useMutationResourceTitleRename();

  const user = useAuthUser();

  const isTitleEditable = resource?.kind !== 'bookmark' && !isAnon;

  const titleInput = useInputControls(resourceTitle);

  return (
    <NewModalWrapper>
      <ResourceTitleEditable
        titleInput={titleInput}
        onSubmit={(value) => {
          if (!resource || resource.isDraft) {
            /**
             * @TODO
             * currently on mobile this doesn't support draft
             */
            // setNeedsSaving(true)
            return;
          }
          if (isTitleEditable && resourceTitle !== value && !!resource) {
            mutateResourceTitleRename({
              resource: { id: resource.id, kind: resource.kind },
              newTitle: value,
            });
          }
        }}
        readOnly={!isTitleEditable}
        user={user}
      />
    </NewModalWrapper>
  );
};
