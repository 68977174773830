import { IconLinkArrow } from '@/src/modules/icons';
import { useResourceHighlightDataSafe } from '@/src/modules/resource-detail/components/context/resourceDataContext';
import { ExpandedResourceDropdownMenu } from '@/src/modules/resource-detail/components/ExpandedResource/components/ExpandedResourceDropdownMenu';
import { HeaderWrapper } from '@/src/modules/resource-detail/components/ExpandedResource/components/ModalResourceHeader';
import { ResourceTitleUrlOverride } from '@/src/modules/resource-detail/components/ExpandedResource/components/ResourceTitleUrlOverride';
import { ResourceHighlightText } from '@/src/modules/resource-detail/components/ExpandedResource/Content/Text/ResourceHighlightText';
import { LinkExternal } from '@/src/modules/ui/components/Link/LinkExternal';
import Modal from '@/src/modules/ui/components/Modal';

export const ResourceContentText: React.FC = () => {
  const { resource } = useResourceHighlightDataSafe();

  return (
    <>
      <HeaderWrapper>
        <HeaderWrapper.Left>
          <ResourceTitleUrlOverride />
        </HeaderWrapper.Left>

        <HeaderWrapper.Right>
          {resource.originUrl && (
            <Modal.IconButton as={LinkExternal} href={resource.originUrl}>
              <IconLinkArrow style={{ width: 14 }} />
            </Modal.IconButton>
          )}
          <ExpandedResourceDropdownMenu />
        </HeaderWrapper.Right>
      </HeaderWrapper>
      <ResourceHighlightText />
    </>
  );
};
