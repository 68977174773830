import { MediaContent } from '@/src/components/ExpandedFdoc/MediaContent';
import { useDownloadResource } from '@/src/hooks/useDownloadFdoc';
import { useResourceImageDataSafe } from '@/src/modules/resource-detail/components/context/resourceDataContext';
import { ExpandedResourceDropdownMenu } from '@/src/modules/resource-detail/components/ExpandedResource/components/ExpandedResourceDropdownMenu';
import { HeaderWrapper } from '@/src/modules/resource-detail/components/ExpandedResource/components/ModalResourceHeader';
import { ExpandedResourceTitle } from '@/src/modules/resource-detail/components/ExpandedResource/components/ResourceTitle';
import { ResourceTitleUrlOverride } from '@/src/modules/resource-detail/components/ExpandedResource/components/ResourceTitleUrlOverride';
import { ButtonIconDownload } from '@/src/modules/ui/components/button/ButtonIconDownload';
import React from 'react';

export const ResourceContentImage = () => {
  const imgRef = React.useRef<HTMLImageElement | null>(null);
  const { resource } = useResourceImageDataSafe();
  const downloadResource = useDownloadResource(resource);

  const isWebnoteImage = !!resource.originUrl;

  return (
    <>
      <HeaderWrapper>
        <HeaderWrapper.Left>
          {isWebnoteImage ? <ResourceTitleUrlOverride /> : <ExpandedResourceTitle />}
        </HeaderWrapper.Left>
        <HeaderWrapper.Right>
          {downloadResource && <ButtonIconDownload onClick={downloadResource} tabIndex={4} />}
          <ExpandedResourceDropdownMenu />
        </HeaderWrapper.Right>
      </HeaderWrapper>
      <MediaContent imageRef={imgRef} disableGestures={false} />
    </>
  );
};
