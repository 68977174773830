import { ResourceContent } from '@/src/modules/resource-detail/components/ExpandedResource/Content/ResourceContent';
import {
  BASE_BOTTOM_BAR_HEIGHT,
  BottomBar,
  DragHandle,
} from '@/src/modules/resource-detail/components/ExpandedResource/MobileView/ResizableBottomBar';
import { cssVar } from '@/src/modules/ui/theme/variables';

import {
  ExpandedFdocProvider,
  useExpandedFdocContext,
} from '@/src/components/ExpandedFdoc/ExpandedFdocProvider';
import { useAuthIsLoggedIn } from '@/src/hooks/auth';
import { isInMobile } from '@/src/hooks/mobile';
import useVisualViewport from '@/src/hooks/useVisualViewport';
import { pick } from '@/src/lib/store';
import { ResourceDataContextProvider } from '@/src/modules/resource-detail/components/context/ResourceDataContextProvider';
import { ModalExpandedResourceEditTags } from '@/src/modules/resource-detail/components/ExpandedResource/components/ModalExpandedResourceEditTags';
import { ModalExpandedResourceShare } from '@/src/modules/resource-detail/components/ExpandedResource/components/ModalExpandedResourceShare';
import { ModalExpandedResourceTagButton } from '@/src/modules/resource-detail/components/ExpandedResource/components/ModalExpandedResourceTagButton';
import { ResourceContentLoadError } from '@/src/modules/resource-detail/components/ExpandedResource/Content/LoadError/ResourceContentLoadError';
import { ResourceContentSkeleton } from '@/src/modules/resource-detail/components/ExpandedResource/Content/Skeleton/ResourceContentSkeleton';
import { ExtraPanelContent } from '@/src/modules/resource-detail/components/ExpandedResource/ExtraPanel/ExtraPanelContent';
import { useQueryResourceDetail } from '@/src/modules/resources/queries/useQueryResourceDetail';
import { useIntersectionObserver } from '@/src/modules/ui/components/IntersectionObserver/useIntersectionObserver';
import { usePanning } from '@/src/modules/ui/hooks/usePanning';
import { mediaMobile } from '@/src/modules/ui/styled-utils';
import useUIStore from '@/src/store/ui';
import { motion } from 'framer-motion';
import React from 'react';
import styled, { css } from 'styled-components';
import { shallow } from 'zustand/shallow';

const Content = styled.div.attrs<{
  occupyFullscreen?: boolean;
  isInMobileApp?: boolean;
  visualHeight?: number;
  visualTop?: number;
}>((props) => {
  const { isEditorFocused } = useExpandedFdocContext();

  const viewport = useVisualViewport({
    recalculateKey: isEditorFocused,
  });

  return {
    ...props,
    occupyFullscreen: isEditorFocused,
    isInMobileApp: isInMobile(),
    visualHeight: viewport?.height ?? 0,
    visualTop: viewport?.offsetTop ?? 0,
  };
})`
  height: 1px;
  flex-grow: 1;
  background: ${cssVar['color-bg-primary']};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  border-radius: 0 0 1.5rem 1.5rem;
  transition: 0.35s max-height ease-out;

  ${mediaMobile} {
    ${(p) => {
      if (p.occupyFullscreen) {
        if (p.isInMobileApp) {
          // visual height hack doesn't work in the app...
          return css`
            min-height: calc(100vh - var(--keyboard-height, 0px)) !important;
            max-height: calc(100vh - var(--keyboard-height, 0px)) !important;

            border-radius: 0;
          `;
        } else if (p.visualHeight || p.visualTop) {
          return css`
            margin-top: ${p.visualTop}px;
            min-height: ${p.visualHeight}px !important;
            max-height: ${p.visualHeight}px !important;
            border-radius: 0;
          `;
        }
      }
    }}
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  box-shadow: 0 0 10px 0 rgba(${cssVar['color-bg-primary-reverse-rgb']}, 0.1);

  /** when keyboard is opened, e.g. notepad, we shirnk the content */
  height: 100%;
  max-height: min(calc(100vh - var(--keyboard-height, 0px)), 100dvh);
`;

export const ResourceViewMobile: React.FC<{
  resourceId: string;
  isVisible: boolean;
  handleOnDelete: VoidFunction;
}> = React.memo(function ResourceViewUnwrapped({ resourceId, isVisible, handleOnDelete }) {
  const { resource, isLoading } = useQueryResourceDetail(resourceId);

  const containerRef = React.useRef<HTMLDivElement>(null);
  const isInView = useIntersectionObserver({
    target: containerRef,
    rootMargin: '0px 0px 0px 0px',
  });

  const isLoggedIn = useAuthIsLoggedIn();

  const { expandedFdocSidebarInitTab } = useUIStore(
    (s) => pick(s, ['expandedFdocSidebarInitTab']),
    shallow,
  );

  /**
   * use this property whether to render the content or not
   * good for some optimization, like not rendering e.g. comments if the resource is not visible
   */
  const isContentEnabled = isVisible && !!resource && isInView;

  /*********************************************************************************
   * resizing
   */
  const [bottomAdditionalHeight, setBottomAdditionalHeight] = React.useState(
    expandedFdocSidebarInitTab && isLoggedIn ? 250 : 0,
  );

  const { motionProps, isPanningY } = usePanning({
    onPan: (e, info, moreInfo) => {
      if (moreInfo.axis === 'y') {
        setBottomAdditionalHeight((prev) => prev + -info.delta.y);
      }
    },
    onPanEnd: (e, { velocity }) => {
      /**
       * swipe down fast, collapse completely
       */
      if (velocity.y > 150) {
        setBottomAdditionalHeight(0);
        return;
      }

      /**
       * swipe up fast, expanded fully
       */
      if (velocity.y < -150) {
        setBottomAdditionalHeight(window.innerHeight - 100);
        return;
      }

      /**
       * if size is not big enough, snap to bottom
       */
      setBottomAdditionalHeight((prev) => (prev < 100 ? 0 : prev));
    },
  });

  const bottomHeight = `calc(${BASE_BOTTOM_BAR_HEIGHT} + ${bottomAdditionalHeight}px)`;

  return (
    <ExpandedFdocProvider handleOnDelete={handleOnDelete}>
      {isContentEnabled && (
        <>
          <ModalExpandedResourceEditTags resourceId={resourceId} />
          <ModalExpandedResourceShare resourceId={resourceId} />
        </>
      )}
      <Wrapper as={motion.div} ref={containerRef}>
        <ResourceDataContextProvider resource={resource}>
          <Content>
            {isLoading ? (
              <ResourceContentSkeleton />
            ) : resource ? (
              <>
                {isContentEnabled && <ModalExpandedResourceTagButton resourceId={resourceId} />}
                <ResourceContent />
              </>
            ) : (
              <ResourceContentLoadError />
            )}
          </Content>
          <DragHandle
            style={{ pointerEvents: !resource ? 'none' : 'auto' }}
            data-pan-id-ignore-expanded-resource="y"
            {...motionProps}
          />
          <BottomBar
            style={{
              height: bottomHeight,
              transition: isPanningY ? undefined : 'height 0.1s ease-out',
            }}
            {...motionProps}
          >
            <ExtraPanelContent
              resourceId={resourceId}
              onTabItemSelect={() => {
                if (bottomAdditionalHeight < 250) {
                  setBottomAdditionalHeight(250);
                }
              }}
              isContentEnabled={isContentEnabled}
            />
          </BottomBar>
        </ResourceDataContextProvider>
      </Wrapper>
    </ExpandedFdocProvider>
  );
});
