import { ResourceVideoPlayer } from '@/src/modules/resource-detail/components/ExpandedResource/Content/Video/ResourceVideoPlayer';
import { ExpandedResourceDropdownMenu } from '@/src/modules/resource-detail/components/ExpandedResource/components/ExpandedResourceDropdownMenu';
import { HeaderWrapper } from '@/src/modules/resource-detail/components/ExpandedResource/components/ModalResourceHeader';
import { ExpandedResourceTitle } from '@/src/modules/resource-detail/components/ExpandedResource/components/ResourceTitle';
import { useResourceVideoDataContextSafe } from '@/src/modules/resource-detail/components/context/resourceDataContext';
import { Flex } from '@/src/modules/ui/components/Flex';
import { ButtonIconDownload } from '@/src/modules/ui/components/button/ButtonIconDownload';
import React from 'react';

export const ResourceContentVideo: React.FC = () => {
  const { handleDownload } = useResourceVideoDataContextSafe();

  return (
    <>
      <HeaderWrapper>
        <HeaderWrapper.Left>
          <ExpandedResourceTitle />
        </HeaderWrapper.Left>
        <HeaderWrapper.Right>
          <ButtonIconDownload onClick={handleDownload} tabIndex={4} />
          <ExpandedResourceDropdownMenu />
        </HeaderWrapper.Right>
      </HeaderWrapper>
      <Flex direction="column" overflow="hidden" flexGrow>
        <ResourceVideoPlayer />
      </Flex>
    </>
  );
};
