import StoredPDFContent from '@/src/components/ExpandedFdoc/ExpandedPDF/StoredPDFContent';
import { PDFDropdownItems } from '@/src/modules/resource-detail/components/ExpandedResource/Content/Document/PDFDropdownItems';
import { ExpandedResourceDropdownMenu } from '@/src/modules/resource-detail/components/ExpandedResource/components/ExpandedResourceDropdownMenu';
import { HeaderWrapper } from '@/src/modules/resource-detail/components/ExpandedResource/components/ModalResourceHeader';
import { ExpandedResourceTitle } from '@/src/modules/resource-detail/components/ExpandedResource/components/ResourceTitle';
import { useResourceDocumentDataContextSafe } from '@/src/modules/resource-detail/components/context/resourceDataContext';
import { Flex } from '@/src/modules/ui/components/Flex';
import { ButtonIconDownload } from '@/src/modules/ui/components/button/ButtonIconDownload';
import React from 'react';

export const ResourceContentDocument: React.FC = () => {
  const { handleDownload } = useResourceDocumentDataContextSafe();

  return (
    <>
      <HeaderWrapper>
        <HeaderWrapper.Left>
          <ExpandedResourceTitle />
        </HeaderWrapper.Left>
        <HeaderWrapper.Right>
          {handleDownload && <ButtonIconDownload onClick={handleDownload} tabIndex={4} />}
          <ExpandedResourceDropdownMenu>
            <PDFDropdownItems />
          </ExpandedResourceDropdownMenu>
        </HeaderWrapper.Right>
      </HeaderWrapper>
      <Flex flexGrow={1} direction="column" justifyContent="center" overflow="hidden">
        <StoredPDFContent isFullScreen={false} tinyPagesIsVisible={false} />
      </Flex>
    </>
  );
};
