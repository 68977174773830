import { useResourceDataContextSafe } from '@/src/modules/resource-detail/components/context/resourceDataContext';
import { ResourceContentAudio } from '@/src/modules/resource-detail/components/ExpandedResource/Content/Audio/ResourceContentAudio';
import { ResourceContentDefault } from '@/src/modules/resource-detail/components/ExpandedResource/Content/Default/ResourceContentStoredFile';
import { ResourceContentDocument } from '@/src/modules/resource-detail/components/ExpandedResource/Content/Document/ResourceContentDocument';
import { ResourceContentImage } from '@/src/modules/resource-detail/components/ExpandedResource/Content/Image/ResourceContentImage';
import { ResourceContentNotepad } from '@/src/modules/resource-detail/components/ExpandedResource/Content/Notepad/ResourceContentNotepad';
import { ResourceContentPage } from '@/src/modules/resource-detail/components/ExpandedResource/Content/Page/ResourceContentPage';
import { ResourceContentText } from '@/src/modules/resource-detail/components/ExpandedResource/Content/Text/ResourceContentText';
import { ResourceContentVideo } from '@/src/modules/resource-detail/components/ExpandedResource/Content/Video/ResourceContentVideo';
import { ResourceHeader } from '@/src/modules/resource-detail/components/ExpandedResource/ResourceHeader';
import {
  isResourceDetailAudio,
  isResourceDetailBookmark,
  isResourceDetailDefault,
  isResourceDetailDocument,
  isResourceDetailHighlight,
  isResourceDetailImage,
  isResourceDetailNotepad,
  isResourceDetailVideo,
} from '@/src/modules/resources/utils/resourceDetailGuards';

export const ResourceContent: React.FC = () => {
  const { resource } = useResourceDataContextSafe();

  if (isResourceDetailBookmark(resource)) {
    return <ResourceContentPage />;
  }

  if (isResourceDetailHighlight(resource)) {
    return <ResourceContentText />;
  }

  if (isResourceDetailImage(resource)) {
    return <ResourceContentImage />;
  }

  if (isResourceDetailVideo(resource)) {
    return <ResourceContentVideo />;
  }

  if (isResourceDetailAudio(resource)) {
    return <ResourceContentAudio />;
  }

  if (isResourceDetailDocument(resource)) {
    return <ResourceContentDocument />;
  }

  if (isResourceDetailDefault(resource)) {
    return <ResourceContentDefault />;
  }

  if (isResourceDetailNotepad(resource)) {
    return <ResourceContentNotepad />;
  }

  return <ResourceHeader />;
};
