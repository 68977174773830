import { useExpandedFdocContext } from '@/src/components/ExpandedFdoc/ExpandedFdocProvider';
import { IconMove, IconTag, IconTrash, IconUserPlus } from '@/src/modules/icons';
import { useResourceDataContextSafe } from '@/src/modules/resource-detail/components/context/resourceDataContext';
import { useMutationDeleteResourcesById } from '@/src/modules/resources/mutations/useMutationDeleteResourcesById';
import { DropdownMenu } from '@/src/modules/ui/components/DropdownMenu/DropdownMenu';
import { setGlobalSelectionOptions } from '@/src/store/ui';

export const ExpandedResourceDropdownMenu: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { setEditTagsModalOpen, setShareModalOpen, handleOnDelete } = useExpandedFdocContext();

  const mutationDeleteResources = useMutationDeleteResourcesById();
  const { readOnly, resource } = useResourceDataContextSafe();

  return (
    <DropdownMenu
      renderTriggerElement={({ onOpenChange }) => (
        <DropdownMenu.TriggerButtonThreeDots onOpenChange={onOpenChange} />
      )}
    >
      <DropdownMenu.Group>
        {children}

        <DropdownMenu.Item iconElement={<IconTag />} onClick={() => setEditTagsModalOpen(true)}>
          Add/edit tags
        </DropdownMenu.Item>
        {!readOnly && (
          <>
            <DropdownMenu.Item
              onClick={() =>
                setGlobalSelectionOptions({
                  selectedFdocsIds: [resource.id],
                  clearOnClose: true,
                })
              }
              data-testid="fdoc-add-to-space-button"
              tabIndex={5}
              iconElement={<IconMove />}
            >
              Move...
            </DropdownMenu.Item>
            <DropdownMenu.Item
              iconElement={<IconUserPlus />}
              onClick={() => setShareModalOpen(true)}
              tabIndex={5}
            >
              {resource.isDirectShared ? 'Manage sharing' : 'Share'}
            </DropdownMenu.Item>
          </>
        )}
      </DropdownMenu.Group>

      {!readOnly && (
        <DropdownMenu.Group>
          <DropdownMenu.Item
            iconElement={<IconTrash />}
            variant="danger"
            onClick={() =>
              mutationDeleteResources.confirmAndMutate([resource.id], {
                onSuccess: () => {
                  handleOnDelete();
                },
              })
            }
            data-testid="fdoc-delete-button"
            tabIndex={5}
          >
            Delete
          </DropdownMenu.Item>
        </DropdownMenu.Group>
      )}
    </DropdownMenu>
  );
};
