import { useExpandedFdocContext } from '@/src/components/ExpandedFdoc/ExpandedFdocProvider';
import EditTagsModal from '@/src/components/Tags/EditTagsModal';
import { MagicSuggestionsContextProvider } from '@/src/modules/magic/components/MagicSuggestionsContext';
import { useMutationResourcesTagAssign } from '@/src/modules/tags/mutations/useMutationResourcesTagAssign';
import { useQueryResourceTags } from '@/src/modules/tags/queries/useQueryResourceTags';
import { PrivateTag } from '@fabric/woody-client';

export const ModalExpandedResourceEditTags: React.FC<{ resourceId: string }> = ({ resourceId }) => {
  const mutationResourcesTagAssign = useMutationResourcesTagAssign();

  const onSelectTag = (tag: PrivateTag, selected: boolean) => {
    mutationResourcesTagAssign.mutate({
      tag,
      resourceIds: [resourceId],
      operation: selected ? 'assign' : 'unassign',
    });
  };

  const { editTagsModalOpen, setEditTagsModalOpen } = useExpandedFdocContext();

  const queryResourceTags = useQueryResourceTags(resourceId);

  return (
    <MagicSuggestionsContextProvider
      context={
        editTagsModalOpen
          ? {
              resourceId,
            }
          : undefined
      }
      includeFolders={false}
    >
      <EditTagsModal
        onSelect={onSelectTag}
        selectedTags={queryResourceTags.data ?? []}
        open={editTagsModalOpen}
        onOpenChange={setEditTagsModalOpen}
      />
    </MagicSuggestionsContextProvider>
  );
};
